import React, { useState, useRef } from 'react';
import { compose } from 'redux';
import { connect, useDispatch, useSelector } from 'react-redux';

import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { isNil } from 'lodash';

import Header from '../../../NewLandingPage/Sections/Header/Header';
import Footer from '../../../NewLandingPage/Sections/Footer/Footer';
import { Page } from '../../../../components';
import StepNavigation from '../StepSection';

import * as coreAPI from '../../../../coreApi';

import { Formik, Form, Field, ErrorMessage, useFormikContext } from 'formik';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';

import TextField from '../../../../newComponents/Form/TextField';
import CheckBoxInfo from '../../../../newComponents/checkbox';
import CustomRadioSelect from '../../../../newComponents/SelectRadioDropdown';
import { OrangeBtn } from '../../../../newComponents/Button/ButtonSkin';

import { IoIosArrowBack } from 'react-icons/io';
import { IoIosArrowForward } from 'react-icons/io';
import { FaRegEye } from 'react-icons/fa';
import { FaRegEyeSlash } from 'react-icons/fa';

import ReCaptcha from 'react-google-recaptcha';
import { GoogleLogin } from 'react-google-login';

import { MainForm, FormSection } from '../Styles';
import { DetailFormSection } from './Styles';

import { signupInitialValues } from './SignupInitialValues';
import { signupValScheme } from './SignupValScheme';
import { isSignupEmailTakenError } from '../../../../util/errors';
import { fetchCurrentUser } from '../../../../ducks/user.duck';
import { authenticationInProgress, signup, signUpWithGoogle } from '../../../../ducks/Auth.duck';
import { saveUserInterestForGuardianRole } from '../../utils';

const optionsLocation = [
    { name: 'regionTarget', value: <FormattedMessage id="SignupForm.locationSelect" /> },
    { name: 'outside-peru', value: <FormattedMessage id="SignupForm.locationOutsidePeru" /> },
    { name: 'Amazonas', value: 'Amazonas' },
    { name: 'Áncash', value: 'Áncash' },
    { name: 'Apurímac', value: 'Apurímac' },
    { name: 'Arequipa', value: 'Arequipa' },
    { name: 'Ayacucho', value: 'Ayacucho' },
    { name: 'Cajamarca', value: 'Cajamarca' },
    { name: 'Cusco', value: 'Cusco' },
    { name: 'Huancavelica', value: 'Huancavelica' },
    { name: 'Huánuco', value: 'Huánuco' },
    { name: 'Ica', value: 'Ica' },
    { name: 'Junín', value: 'Junín' },
    { name: 'La Libertad', value: 'La Libertad' },
    { name: 'Lambayeque', value: 'Lambayeque' },
    { name: 'Lima', value: 'Lima' },
    { name: 'Loreto', value: 'Loreto' },
    { name: 'Madre de Dios', value: 'Madre de Dios' },
    { name: 'Moquegua', value: 'Moquegua' },
    { name: 'Pasco', value: 'Pasco' },
    { name: 'Piura', value: 'Piura' },
    { name: 'Puno', value: 'Puno' },
    { name: 'San Martín', value: 'San Martín' },
    { name: 'Tacna', value: 'Tacna' },
    { name: 'Tumbes', value: 'Tumbes' },
    { name: 'Ucayali', value: 'Ucayali' },
];
const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID || null;

const GuardianSignup = props => {
    const dispatch = useDispatch();
    const historyRef = useHistory();
    const selectedRole = useSelector(state => state.GuardianReducer.selectedRole);

    if (!selectedRole) historyRef.push('/land-managers/role');
    console.log('props, ', props);
    const {
        location,
        user,
        history,
        isAuthenticated,
        signupError,
        intl,
        currentUser,
        language,
    } = props;

    const [showError, setShowError] = useState(true);
    const [isChecked, setIsChecked] = useState(true);
    const [regularAccError, setRegularAccError] = useState(false);

    const consentLabel = (
        <small>
            <FormattedMessage id="SignupForm.NewsletterForm.Consent" />
        </small>
    );

    const [showPassword, setShowPassword] = useState(false);

    const togglepassword = () => {
        setShowPassword(prevState => !prevState);
    };

    const reCaptchaRefSignup = useRef(null);
    const reCaptchaRefSignupNewsletter = useRef(null);
    const formikRef = useRef(); // Create a ref to access Formik's methods

    const onSubmit = async (values, actions) => {
        const { ...restValues } = values;

        try {
            setShowError(false);

            if (isChecked) {
                await checkCaptchaNewsLetter(restValues);
            }

            let captchaToken;
            if (reCaptchaRefSignup.current) {
                captchaToken = await reCaptchaRefSignup.current.executeAsync();
                reCaptchaRefSignup.current.reset();
            }

            if (!!captchaToken && !!restValues) {
                await props.submitSignup({ ...restValues, captchaToken });

                await props.fetchCurrentUser();
            } else {
                console.error('Failed to generate captchaToken');

                return; // Exit early if captchaToken is undefined
            }
        } catch (err) {
            console.log(err);
        } finally {
            setRegularAccError(true);
            setShowError(true);
        }
    };

    const onContinue = async values => {
        const { languagePreferred, regionTarget } = values;
        console.log('langagsd', languagePreferred, regionTarget, selectedRole);
        try {
            setShowError(false);

            // add preferred language and location and chosen role to interested database

            if (!!regionTarget && !!selectedRole && !!languagePreferred) {
                const regionCountry = regionTarget !== 'outside-peru' ? 'Peru' : null;
                const interestData = {
                    guardianSubRoleId: selectedRole,
                    documentLanguage: languagePreferred,
                    region: regionTarget,
                    regionCountry,
                };
                const result = await saveUserInterestForGuardianRole(interestData);
                if (result.status === 'success') {
                    if (regionTarget === 'outside-peru') {
                        historyRef.push('/land-managers/select-country');
                    } else {
                        historyRef.push(
                            '/land-managers/sign-document?region=' +
                                regionTarget +
                                '&lang=' +
                                languagePreferred
                        );
                    }
                } else {
                    setShowError(result.message);
                }
            }
        } catch (err) {
            console.log(err);
        } finally {
            setRegularAccError(true);
            setShowError(true);
        }
    };
    const checkCaptchaNewsLetter = async restValues => {
        let captchaToken;
        if (reCaptchaRefSignupNewsletter.current) {
            captchaToken = await reCaptchaRefSignupNewsletter.current.executeAsync();
            reCaptchaRefSignupNewsletter.current.reset();
        }

        if (!!captchaToken && !!restValues) {
            try {
                await coreAPI.subscribeToNewsLetter(
                    {
                        firstname: restValues.firstName,
                        lastname: restValues.lastName,
                        email: restValues.email,
                        language: intl.locale,
                        statusIfNew: 'subscribed',
                        status: 'subscribed',
                    },
                    captchaToken
                );
            } catch (e) {
                console.log(e);
            }
        }
    };

    const responseGoogle = async response => {
        setShowError(false);
        if (response.tokenId) {
            try {
                let captchaToken;
                if (reCaptchaRefSignup.current) {
                    captchaToken = await reCaptchaRefSignup.current.executeAsync();
                    reCaptchaRefSignup.current.reset();
                }
                await props.submitSignupWithGoogle({
                    tokenId: response.tokenId,
                    captchaToken,
                });
            } catch (error) {
                console.log({ 'Google Sign In Error': error });
            } finally {
                setRegularAccError(false);
                setShowError(true);
                //setScroll(true);
            }
        }
    };
    const currentUserDetails = isAuthenticated
        ? {
              firstName: (currentUser && currentUser.attributes.profile.firstName) || '',
              lastName: (currentUser && currentUser.attributes.profile.lastName) || '',
              email: (currentUser && currentUser.attributes.email) || '',
              password: 'dummytext@123',
              languagePreferred: language,
              regionTarget: '',
          }
        : {
              firstName: '',
              lastName: '',
              email: '',
              password: '',
              languagePreferred: language,
              regionTarget: '',
          };
    const initialValues = { ...signupInitialValues, ...currentUserDetails };
    console.log('currentUserDetails', signupInitialValues, currentUserDetails, initialValues);
    const validationSchema = signupValScheme;

    // firstName
    const firstNameLabel = intl.formatMessage({
        id: 'SignupForm.firstNameLabel',
    });
    const firstNamePlaceholder = intl.formatMessage({
        id: 'SignupForm.firstNamePlaceholder',
    });

    // lastName
    const lastNameLabel = intl.formatMessage({
        id: 'SignupForm.lastNameLabel',
    });
    const lastNamePlaceholder = intl.formatMessage({
        id: 'SignupForm.lastNamePlaceholder',
    });

    const emailLabel = intl.formatMessage({
        id: 'SignupForm.emailLabel',
    });
    const emailPlaceholder = intl.formatMessage({
        id: 'SignupForm.emailPlaceholder',
    });

    // password
    const passwordLabel = intl.formatMessage({
        id: 'SignupForm.passwordLabel',
    });
    const passwordPlaceholder = intl.formatMessage({
        id: 'SignupForm.passwordPlaceholder',
    });

    // language
    const langauageLabel = intl.formatMessage({
        id: 'SignupForm.languageLabel',
    });

    // location
    const locationLabel = intl.formatMessage({
        id: 'SignupForm.locationLabel',
    });
    ///

    const termsLink = (
        <Link to="/terms-of-service" target="_blank">
            <FormattedMessage id="SignupForm.termsAndConditionsLinkText" />
        </Link>
    );

    const privacyLink = (
        <Link to="/privacy-policy" target="_blank">
            <FormattedMessage id="SignupForm.privacyPolicyLinkText" />
        </Link>
    );

    return (
        <Page>
            <Header currentPath={location.pathname} user={user} history={history} />
            <MainForm>
                <StepNavigation step={2} />
                <FormSection>
                    <div className="show-flex-tab justify-center breadcrumb mx-auto flex">
                        <Link className="breadbrumb-link" to="/land-managers/role">
                            <FormattedMessage id="GuardianSubscription.StepForm.Step1.title" />
                        </Link>
                        <Link className="breadbrumb-link active">
                            <FormattedMessage id="GuardianSubscription.StepForm.FillDetails" />
                        </Link>
                        <Link className="breadbrumb-link">
                            <FormattedMessage id="GuardianSubscription.StepForm.Step3.title" />
                        </Link>
                    </div>
                    <div className="formnextprev justify-space-between relative hide-tab w-full flex items-center">
                        <button
                            className="prev-btn absolute flex items-center"
                            onClick={() => historyRef.push('/land-managers/role')}
                        >
                            <IoIosArrowBack />{' '}
                            <small>
                                <FormattedMessage id="Subscription.StepForm.previous" />
                            </small>
                        </button>
                        <button
                            className={`next-btn absolute flex items-center ${
                                !formikRef.isValid ? 'disable' : null
                            }`}
                            onClick={formikRef.isValid && formikRef.submitForm()}
                        >
                            <small>
                                <FormattedMessage id="Subscription.StepForm.next" />
                            </small>{' '}
                            <IoIosArrowForward />
                        </button>
                    </div>
                    <DetailFormSection className="mx-auto flex flex-col">
                        <div className="form-title small-title text-center">
                            <FormattedMessage id="GuardianSubscription.StepForm.Step2.title" />
                            <div className="title-info text-center">
                                <FormattedMessage id="GuardianSubscription.StepForm.Step2.desc" />
                            </div>
                        </div>

                        <Formik
                            ref={formikRef}
                            enableReinitialize={true}
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={isAuthenticated ? onContinue : onSubmit}
                            validateOnMount={true}
                        >
                            {formik => {
                                return (
                                    <Form onChange={() => setShowError(false)}>
                                        <ReCaptcha
                                            ref={reCaptchaRefSignup}
                                            sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                                            size="invisible"
                                        />
                                        <ReCaptcha
                                            ref={reCaptchaRefSignupNewsletter}
                                            sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                                            size="invisible"
                                        />
                                        <div className="form-section flex flex-col">
                                            <div className="two-col flex space-between">
                                                <div className="input-box-section">
                                                    <TextField
                                                        type="text"
                                                        label={firstNameLabel}
                                                        name="firstName"
                                                        placeholder={firstNamePlaceholder}
                                                        aria-describedby="firstnamehelp"
                                                        value={formik.values.firstName}
                                                        className={`form-control  ${formik.touched
                                                            .firstName &&
                                                            formik.errors.firstName &&
                                                            'input-error'}`}
                                                        disabled={isAuthenticated}
                                                    />
                                                </div>
                                                <div className="input-box-section">
                                                    <TextField
                                                        type="text"
                                                        label={lastNameLabel}
                                                        name="lastName"
                                                        placeholder={lastNamePlaceholder}
                                                        value={formik.values.lastName}
                                                        aria-describedby="lastnamehelp"
                                                        className={`form-control  ${formik.touched
                                                            .lastName &&
                                                            formik.errors.lastName &&
                                                            'input-error'}`}
                                                        disabled={isAuthenticated}
                                                    />
                                                </div>
                                            </div>
                                            <div className="one-col">
                                                <div className="input-box-section">
                                                    <TextField
                                                        type="email"
                                                        label={emailLabel}
                                                        name="email"
                                                        placeholder={emailPlaceholder}
                                                        aria-describedby="emailhelp"
                                                        className={`form-control  ${formik.touched
                                                            .email &&
                                                            formik.errors.email &&
                                                            'input-error'}`}
                                                        disabled={isAuthenticated}
                                                    />
                                                </div>
                                            </div>
                                            {!isAuthenticated && (
                                                <div className="one-col">
                                                    <div className="input-box-section">
                                                        <div className="input-group relative">
                                                            <TextField
                                                                type={
                                                                    showPassword
                                                                        ? 'text'
                                                                        : 'password'
                                                                }
                                                                label={passwordLabel}
                                                                name="password"
                                                                placeholder={passwordPlaceholder}
                                                                aria-describedby="passwordhelp"
                                                                className={`form-control  ${formik
                                                                    .touched.password &&
                                                                    formik.errors.password &&
                                                                    'input-error'}`}
                                                                disabled={isAuthenticated}
                                                            />
                                                            <span
                                                                onClick={togglepassword}
                                                                className="input-group-prepend absolute"
                                                            >
                                                                {showPassword ? (
                                                                    <FaRegEye />
                                                                ) : (
                                                                    <FaRegEyeSlash />
                                                                )}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}

                                            <div className="two-col flex space-between">
                                                <div className="input-box-section">
                                                    <label className="small">
                                                        {langauageLabel}
                                                    </label>
                                                    <div className="select-language flex space-between">
                                                        <label for="languageOne" class="radio-box">
                                                            <Field
                                                                id="languageOne"
                                                                name="languagePreferred"
                                                                type="radio"
                                                                value={'EN'}
                                                            />
                                                            <div className="language-btn flex justify-center items-center">
                                                                English
                                                            </div>
                                                        </label>
                                                        <label for="languageTwo" class="radio-box">
                                                            <Field
                                                                id="languageTwo"
                                                                name="languagePreferred"
                                                                type="radio"
                                                                value={'ES'}
                                                            />
                                                            <div className="language-btn flex justify-center items-center">
                                                                Español
                                                            </div>
                                                        </label>
                                                    </div>
                                                </div>

                                                <div className="input-box-section">
                                                    <label className="small">{locationLabel}</label>

                                                    <CustomRadioSelect
                                                        name="regionTarget"
                                                        optionsList={optionsLocation}
                                                        value={formik.values.regionTarget}
                                                        onChange={value =>
                                                            formik.setFieldValue(
                                                                'regionTarget',
                                                                value
                                                            )
                                                        }
                                                        disabled={false}
                                                        classname={`form-control ${
                                                            formik.errors.regionTarget
                                                                ? 'input-error'
                                                                : ''
                                                        }`}
                                                    />
                                                    <ErrorMessage
                                                        name="regionTarget"
                                                        component="span"
                                                        className="error-text small"
                                                    />
                                                </div>
                                            </div>

                                            {!isAuthenticated && (
                                                <div className="one-col">
                                                    <CheckBoxInfo
                                                        labelName={consentLabel}
                                                        labelId="consent"
                                                        checkboxSection="consent-box"
                                                        isChecked={isChecked}
                                                        onChange={() => setIsChecked(!isChecked)}
                                                    />
                                                </div>
                                            )}
                                        </div>

                                        <div className="cta mx-auto">
                                            <OrangeBtn
                                                type="submit"
                                                disabled={!formik.isValid}
                                                className={!formik.isValid ? 'disabled' : null}
                                            >
                                                {isAuthenticated ? (
                                                    <FormattedMessage id="NewPartnersPage.Setup.Account.continue" />
                                                ) : (
                                                    <FormattedMessage id="Subscription.StepForm.Signup.btn" />
                                                )}
                                            </OrangeBtn>
                                            {signupError && !regularAccError && showError && (
                                                <div className="error-text small">
                                                    {isSignupEmailTakenError(signupError) ? (
                                                        <>
                                                            <FormattedMessage id="AuthenticationPage.signupFailedEmailAlreadyTaken" />
                                                        </>
                                                    ) : (
                                                        <FormattedMessage id="AuthenticationPage.signupFailed" />
                                                    )}
                                                </div>
                                            )}

                                            {!isAuthenticated && (
                                                <>
                                                    <div className="googleSignUp">
                                                        <h5>or</h5>
                                                        {googleClientId && (
                                                            <GoogleLogin
                                                                className="login-w-google flex items-center justify-center"
                                                                clientId={googleClientId}
                                                                disabled={isAuthenticated}
                                                                buttonText={
                                                                    <FormattedMessage id="NewPartnersPage.Setup.Account.GoogleBtn" />
                                                                }
                                                                onSuccess={responseGoogle}
                                                                onFailure={responseGoogle}
                                                                cookiePolicy={'single_host_origin'}
                                                            />
                                                        )}
                                                    </div>
                                                    <div className="login-cta small">
                                                        <FormattedMessage
                                                            id="SignupForm.termsAndConditionsAcceptText"
                                                            values={{ termsLink }}
                                                        />
                                                        <FormattedMessage
                                                            id="SignupForm.privacyPoliceAcceptText"
                                                            values={{ privacyLink }}
                                                        />
                                                    </div>

                                                    <div className="login-cta small">
                                                        <FormattedMessage id="SignupForm.AlreadyAccount" />{' '}
                                                        <Link
                                                            to={{
                                                                pathname: '/login',
                                                                state: {
                                                                    from:
                                                                        location.pathname +
                                                                        location.search,
                                                                }, // Pass the current path as `from`
                                                            }}
                                                        >
                                                            <FormattedMessage id="SignupForm.LoginTxt" />
                                                        </Link>
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    </Form>
                                );
                            }}
                        </Formik>

                        <div className="step-nav hide-tab flex mx-auto">
                            <div className="bullet"></div>
                            <div className="bullet current"></div>
                            <div className="bullet"></div>
                        </div>
                    </DetailFormSection>
                </FormSection>
            </MainForm>
            <Footer history={history} />
        </Page>
    );
};

const mapDispatchToProps = dispatch => ({
    submitSignup: params => dispatch(signup(params)),
    submitSignupWithGoogle: params => dispatch(signUpWithGoogle(params)),
    fetchCurrentUser: params => dispatch(fetchCurrentUser()),
});

const mapStateToProps = state => {
    const user = state.user.currentUser
        ? {
              initials: state.user.currentUser.attributes.profile.abbreviatedName,
              name:
                  state.user.currentUser.attributes.profile.firstName +
                  ' ' +
                  state.user.currentUser.attributes.profile.lastName,
              email: state.user.currentUser.attributes.email,
              firstName: state.user.currentUser.attributes.profile.firstName,
              lastName: state.user.currentUser.attributes.profile.lastName,
              cartCount: state.user.currentUser.cartCount,
              currentRole: state.user.currentUser.currentRole,
              activatedRoles: state.user.currentUser.activatedRoles,
          }
        : null;

    const language = state.Languages.selectedLanguage.short;
    const { isAuthenticated, signupError } = state.Auth;
    const { currentUser } = state.user;
    return {
        user,
        authInProgress: authenticationInProgress(state),
        currentUser,
        isAuthenticated,
        signupError,
        language,
    };
};

export default compose(injectIntl)(connect(mapStateToProps, mapDispatchToProps)(GuardianSignup));
